//types
import type { MouseEvent } from 'react';
import type { ImageField } from '@prismicio/types';

import { LinkWrap } from '../../navigation/LinkWrap';
import { cn } from '@virginexperiencedays/tailwind';
import { VisualHeading } from '../../typography/VisualHeading';
import { BodyText } from '../../typography/BodyText';

import { Overtitle } from './Overtitle';

export type HeroStyle03Props = {
  title: string;
  body: string;
  ctaLabel: string;
  link: string;
  isLinkExternal: boolean;
  image: {
    mobile: Pick<ImageField, 'url' | 'alt'>;
    tablet: Pick<ImageField, 'url' | 'alt'>;
    desktop: Pick<ImageField, 'url' | 'alt'>;
  };
  className?: string;
  dataTestId?: string;
  overTitle?: string;
  imageIsPriority?: boolean;
  onClick?: (e: MouseEvent<HTMLElement>) => void;
};

export const HeroStyle03 = ({
  className,
  overTitle,
  title,
  body,
  link,
  isLinkExternal,
  image,
  imageIsPriority,
  onClick,
  ctaLabel = 'Shop Now',
  dataTestId = 'hero-style-03',
}: HeroStyle03Props) => {
  const mobile = image?.mobile;
  const tablet = image?.tablet;
  const desktop = image?.desktop;

  const baseImage = mobile?.url ? mobile : desktop;

  if (!mobile?.url && !desktop?.url) return null;

  return (
    <LinkWrap
      className={cn('text-neutral cursor-pointer', className)}
      dataTestId={dataTestId}
      href={link}
      isExternal={isLinkExternal}
      onClick={onClick}
    >
      <div className="mx-auto max-w-[680px] px-4 pt-10 text-center">
        {!!overTitle && <Overtitle className="mb-1">{overTitle}</Overtitle>}
        {!!title && (
          <VisualHeading
            size="2"
            className="font-normal leading-none -tracking-[0.02em] sm:text-4xl/none md:text-5xl/none lg:text-[3.5rem] xl:text-[4rem]"
          >
            {title}
          </VisualHeading>
        )}
        {!!body && <BodyText className="mt-4 text-xl leading-normal">{body}</BodyText>}
        {!!ctaLabel && (
          <BodyText className="bg-background-primary hover:bg-background-primary-highlight focus:bg-background-primary mt-6 inline-block w-full select-none rounded border border-2 border-transparent px-10 py-3.5 text-center align-middle text-base font-semibold leading-none text-white transition-colors md:mt-6 md:w-auto">
            {ctaLabel}
          </BodyText>
        )}
      </div>
      <div className="mt-6 text-center">
        <picture>
          {desktop?.url && (
            <source
              media={tablet?.url ? '(min-width: 992px)' : '(min-width: 800px)'}
              srcSet={desktop.url}
            />
          )}
          {tablet?.url && <source media="(min-width: 768px)" srcSet={tablet.url} />}

          <img
            className="max-w-full"
            src={baseImage.url}
            alt={baseImage.alt ?? title}
            width="100%"
            height="auto"
            loading={imageIsPriority ? 'eager' : 'lazy'}
          />
        </picture>
      </div>
    </LinkWrap>
  );
};
